<template>
  <div></div>
</template>

<script>
export default {
  created() {
    this.getCode()
  },
  methods: {
    getCode(){
      var code = window.location.search.split("=")[1].split("&")[0];
      sessionStorage.setItem('code', code);
      console.log(code)
      this.$http.get(`/wx/pcauth?code=${code}&userId=${119}`).then(
        response => {
          console.log(response)
          console.log(response.data)
          if(response.data.code==0){
            var userInfo = JSON.stringify(response.data.data) 
            sessionStorage.setItem('userInfo',userInfo)
            var hpurl = sessionStorage.getItem('hpurl');
            window.location.href = hpurl;
          }
        }
      )
      
    }
  },
}
</script>

<style>

</style>