<template>
  <div id="pageWarpp">
    <div class="container">
      <div id="lk-banner">
        <div class="row">
          <div class="col-12">
            <div class="banner_box">
              <img src="http://wwwsauoscom.oss-cn-shanghai.aliyuncs.com/www/images/07cjwt/jjwt.jpg?x-oss-process=style/yilininfo" alt="">
            </div>
          </div>
        </div>
      </div>
      <div id="lk-main" class="mt-4">
        <div class="row">
          <div class="col-md-10 col-lg-9 col-xl-7">
            <div class="title"><h4>直播对网络的要求有多高</h4></div>
            <div class="content">网络视频直播对上行带宽有严格要求，但在不同的场景下对网络的要求也是不一样的，如果是高清720P直播，需要的上行带宽建议为800K 以上；如果是标清直播，上行带宽需达到450K 以上，视频400K，音频64K如果是高清720P 直播，需要的上行带宽建议为800K 以上。</div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-10 col-lg-9 col-xl-7">
            <div class="title"><h4>多会场直播可以互动吗？</h4></div>
            <div class="content">如果每个场地、场次都是独立直播，可以创建多个直播间，通过画面切换的方式达到互动体验的效果。给同一个活动的每个场次或场地都建立一个直播间，各自拥有独立的互动体验，相互之间不受影响 以上；如果是标清直播，上行带宽需达到450K 以上，视频400K，音频64K如果是高清720P 直播，需要的上行带宽建议为800K 以上。</div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-10 col-lg-9 col-xl-7">
            <div class="title"><h4>无线网络覆盖如何搭建</h4></div>
            <div class="content">在大型会议场景下构建无线网络，中兴新支点会议场馆WiFi无线网络覆盖解决方案采用ICG+AP的组网方式。会场AP部署位置很重要，这部分内容涉及现场勘测、AP的安装位置确定、设备及附件确认等工作。勘测设计工作直接影响后续的工程实施，所以需要尽可能的详尽和准确。现场工勘要覆盖到需求的每一位置，AP的安装位置需要明确标注。其他的工程附件安装也要遵循高规格要求，不仅要有工程性要求，而且需要考量现场环境协调问题，在工程规范上提出美观性要求 以上；如果是标清直播，上行带宽需达到450K 以上，视频400K，音频64K如果是高清720P 直播，需要的上行带宽建议为800K 以上。</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:'Problem'
  
}
</script>

<style lang="less" scoped>
  #pageWarpp{
    margin-top: 100px;
  }
  #lk-banner{
    img{
      width: 100%;
    }
  }
  #lk-main{
    margin-bottom: 20px;
    .title{
      margin-bottom: 30px;
    }
    .content{
      color: #6E6E6E;
      margin-bottom: 60px;
      text-align: justify;
    }
  }
</style>