<template>
  <div class="page-warpp ">
    <div class="container" id="lk_main">
      <div v-for="(item,index) in list_one" :key="index" class="mb-5 box_left">
        <div v-if="index % 2 || !hover">
          <div class="row no-gutters ">
            <div class="col-md-6 img-box">
              <img :src="item.imgurl" alt="">
            </div>
            <div class="col-md-6 position-relative">
              <div class="content box-shadow" :class="hover?'hover':''">
                <p class="title">{{item.title}}</p>
                <p>{{item.content}}</p>
                <div class="detailed">
                  <router-link to="network">
                    <p class="mt-4">详细  <i class="bi bi-arrow-right"></i></p>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!(index % 2) && hover" class="mb-5 box_right">
          <div class="row no-gutters  clearfix" id="lk-content_right">
            <div class="col-md-6 position-relative">
              <div class="content box-shadow clearfix" :class="hover?'hover':''">
                <p class="title">{{item.title}}</p>
                <p>{{item.content}}</p>
                <div class="detailed">
                  <router-link to="network">
                    <p class="mt-4">详细  <i class="bi bi-arrow-right"></i></p>
                  </router-link>
                </div>
              </div>
            </div>
            <div class="col-md-6 img-box">
              <img :src="item.imgurl" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import elementResizeDetectorMaker from "element-resize-detector"
export default {
  name:'Customized',
  data() {
    return {
      screenWidth:'',
      hover:true,
      list_one:[
        {title:'H5定制开发',content:'丰富的动画设置支持复合动画及动画组，同时支持触发式动画播放及手势播放动画控制强大的触发器交互多样化页面类型，自定义页面尺寸，可方便设计移动端、pad端以及桌面大屏应用。同时，支持H5长页面设计、H5流式页面制作的混合编排详情',imgurl:'http://wwwsauoscom.oss-cn-shanghai.aliyuncs.com/www/images/02wldj/dybf/h501.jpg?x-oss-process=style/yilininfo'},
        {title:'软件系统定制开发',content:'不限地域：全国各地都可以定制，需求沟通无障碍。敏捷开发：边开发边体验边提修改意见。价格一贯：按模块收费，不担心扩展功能时挨宰。部署方式灵活（自部署：可安装部署在自己服务器上，云部署：可部署在云平台上），支持多种使用方式',imgurl:'http://wwwsauoscom.oss-cn-shanghai.aliyuncs.com/www/images/02wldj/dybf/h502.jpg?x-oss-process=style/yilininfo'},
        {title:'小程序定制开发',content:'我们有着资深的客户服务经验，全部通过“标准与专业化”的上岗培训，执行“7X24”的服务机制，随时为客户提供各种咨询。通过上门服务、客户培训、推广方案策划与咨询、流量分析评估、定期回访、网络营销专家培训会议等丰富的服务内容，为所有的客户提供优质贴心的服务',imgurl:'http://wwwsauoscom.oss-cn-shanghai.aliyuncs.com/www/images/02wldj/dybf/h503.jpg?x-oss-process=style/yilininfo'},
        {title:'硬件定制开发',content:'拥有完整的服务体系，涵盖资讯、方案、实施、培训、技术支持等。过硬的产品和技术能力，多年行业的实践经验与积累，是提供专业服务得到基础。良好的技术规范和服务管理滚翻，是提供优质、高效服务的根本保障',imgurl:'http://wwwsauoscom.oss-cn-shanghai.aliyuncs.com/www/images/02wldj/dybf/h504.jpg?x-oss-process=style/yilininfo'},
      ]
    }
  },
  watch:{
    screenWidth(val){
      if(val+17 <= 767){
        this.hover=false
      }else{
        this.hover=true
      }
    }
  },
  mounted() {
    this.screenWidth = document.documentElement.clientWidth
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.documentElement.clientWidth;
      })();
    };
  },
  methods: {

  }  
}
</script>

<style lang='less' scoped>
  .page-warpp{
    margin-top: 150px;
    margin-bottom: 60px;
  }
  #lk_main{
    .img-box{
      img{
        width: 100%;
      }
    }
    .box-shadow{
      box-shadow: 0 10px 20px rgb(23 22 49 / 10%)
    }
    .fl{
      float: left;
    }
    .fr{
      float: right;
    }
    .content{
      padding: 30px;
      background-color: rgba(255, 255, 255, .8);
      position: absolute;
      top: 50%;
      left: -10%;
      z-index: 999;
      transition: all .5s;
      transform: translateY(-50%);
      text-align: justify;
      .title{
        color: #FFB136;
      }
      p{
        margin: 0;
        color: #757474;
      }
      .detailed{
        cursor: pointer;
        font-size: 18px;
        position: relative;
        a:hover{
          text-decoration: none;
        }
        i{
          transition: all .5s;
        }
        p{
          transition: all .5s;
        }
        p:hover{
          color: royalblue;
          i{
            position: absolute;
            transform: translateX(30%);
          }
        }
      }
    }
    .hover:hover{
      transform: translate(-10%,-50%);
    } 
    #lk-content_right{
      .content{
        right: -10% !important;
        left: 0 !important;
      }
      .hover:hover{
        transform: translate(10%,-50%) !important;
      } 
    }
  }
  @media screen and (max-width:767px) {
   #lk_main .content{
     position: relative;
     top: 0;
     left: 0;
     transform: translate(0);
    }
  }
  @media screen and (max-width:991px) and (min-width:767px){
    .content{
      padding: 10px 13px !important;
    }
    
  }
  
</style>