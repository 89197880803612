<template>
  <div class="container">
    <div class="row" id="lk_case">
      <div class="col-sm-10 col-md-4 mb-4" v-for="(item,index) in caseList" :key="index">
        <div class="wow bounceInLeft case_list"  :data-wow-delay="item.delay" data-wow-duration="3s">
          <div class="list_box" >
            <div class="img_box">
              <img :src="item.caseKv" alt="">
            </div>
            <div class="title_box">
              <h5 class="text-center"><strong>{{item.caseTitle}}</strong></h5>
            </div>
            <div class="info_box">
              <p>{{item.caseAddress}} {{item.caseAddress?'|':''}} {{getTime(item.cdate)}}</p>
              <div class="dsc">
                <p>{{item.caseMessage}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
 import {WOW} from 'wowjs'
  export default {
    name:'ActivityCase',
    data() {
      return {
        caseList:'',
        userId:this.$store.state.userId
      }
    },
    mounted() {
      this.getList()
      this.sendData()
      this.$nextTick(()=>{
        const wow = new WOW({
          live:true
        })
        wow.init();
      })
    },
    methods:{
      sendData(){
        this.$bus.$emit('getCaseList',this.caseList)
      },
      getTime(time) {
        var date = new Date(time * 1000),
        Y = date.getFullYear() + "-",
        M =(date.getMonth() + 1 < 10? "0" + (date.getMonth() + 1): date.getMonth() + 1) + "-",
        D = date.getDate(),
        h = date.getHours(),
        m = date.getMinutes(),
        s = date.getSeconds();
        if (D < 10) {
          D = "0" + D;
        }
        if (h < 10) {
          h = "0" + h;
        }
        if (m < 10) {
          m = "0" + m;
        }
        if (s < 10) {
          s = "0" + s;
        }
        //  return Y + M + D + " " + h + ":" + m + ":" + s;
        return Y + M + D
      },
      getList(){
        console.log(this.userId)
        this.$http.post(`/case/getCaseUser`,{userId:this.userId}).then(
          response => {
            var data = response.data
            if(data.code==0){
              var list = response.data.page.list
              this.newList(list)
              this.$nextTick(()=>{
                const wow = new WOW({
                  live:true
                })
                wow.init();
              })
            }
          },error => {

          }
        )
      },
      newList(list){
        var len = Math.floor(list.length/3)
        if(!len){
          len=1;
        }
        for(var i = 1;i<=len;i++){
          console.log(list)
          list.forEach((value,index) => {
            if(1+(i-1)*3==index+1){
              value['delay'] = '0.9s'
            }else if(2+(i-1)*3==index+1){
              value['delay'] = '0.7s'
            }else if(3*i==index+1){
              value['delay'] = '0.5s'
              return
            }
          });
        }
        this.caseList = list
      }
    }
  }
</script>

<style lang='less' scoped>
  #lk_case{
    margin-top: 170px;
    min-height: 704px;
    margin-bottom: 60px;
    .case_list{
      .list_box{
        position: relative;
        border-bottom: 2px solid #E88E00;
      }
      .list_box:after{
        width: 0;
        height: 0;
        content: '';
        border-bottom: 10px solid #E88E00;
        border-right: 10px solid transparent;
        position: absolute;
        bottom: -1px;
        left: 0;
      }
      .img_box{
        height: 15rem;
        overflow: hidden;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .title_box{
        padding: 1.5rem 0;
        background-color: #F4F4F4;
      }
      .title_box:hover{
        background-color: #FFA500;
        color: white;
      }
      .info_box{
        padding: 2rem .6rem;
        color: #7C7E7C;
        font-size: 1.2rem;
        font-weight: 200;
        display: flex;
        // align-items: center;
        flex-direction: column;
        p{
          margin-bottom: 0;
        }
        .dsc{
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
      }
    }
  }
</style>