<template>
  <div class="page_warp">
    <div class="container-xl col-xl-10">
      <div class="row justify-content-center ">
        <div class="col-lg-9 col-md-12 nogetter">
          <div class="video_box ">
            <div class="head_box">
              <div class="logo">
                <img src="http://img.sauos.com/images/yl_ja4791f1c0c3be4ee1ae735d0d862bbcc5.jpg?x-oss-process=style/logo">
              </div>
              <div class="info_box mr-auto">
                <div class="title mb-1">
                  <h4><strong>{{live.title}}</strong></h4>
                </div>
                <div class="info_list">
                  <span class="mr-3"><i class="bi bi-people"></i> {{pv}}</span>
                  <span><i class="bi bi-clock">  {{live.livetime ? getTime(live.livetime) : '待定'}}</i></span>
                </div>
              </div>
              <div class="share text-center" @mouseenter="enterShow" @mouseleave="leaveHidder">
                <div class="img_box mb-2">
                  <img src="http://img.sauos.com/img/pc/img/share.png?x-oss-process=style/logo" alt="">
                </div>
                <div>
                  <p>分享</p>
                </div>
              </div>
            </div>
            <div id="video-box">
              <div class="bullet-chat">
                <div class="chatBox">
                  <div class="passageway" v-for="index in 4" :key="index"></div>
                </div>
              </div>
              <video ref="pageContainer" id="myVideo" class="video-js vjs-big-play-centered" preload="auto" 
                data-setup='{}' controls x5-video-player-type='h5' x5-video-player-fullscreen='true'
                webkit-playsinline="true" x5-video-orientation="portraint" :poster="kvImg">
              </video>
              <div class="qr" v-show="isShow" @mouseenter="enterShow" @mouseleave="leaveHidder">
                <div class="title">
                  <p>扫描二维码进入直播间</p>
                </div>
                <div class="qr-box" >
                  <vue-qr :text="qrData.url" :logoSrc="qrData.icon"
                  backgroundDimming="white" :logoCornerRadius="0" :correctLevel="3"  :logoScale=".25" :logoMargin="5" ></vue-qr>
                </div>
                <div class="url_box">
                  <input type="text" v-model="qrData.url">
                </div>
                <button class="btn_copy" @click="copyCode">复制链接</button>
              </div>
              <div class="kvImg" v-show="kvShow">
                <img :src="kvImg" alt="">
              </div>
              <div class="state" v-show="kvShow">
                <p>{{downTime?'距离直播开始':''}}  <span>{{downTime?'':'直播即将开始，敬请期待'}}{{downTime}}</span></p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 col-lg-3 nogetter" v-show="menu.typeId" >
          <div class="interaction" id="interaction_box">
            <div class="title text-center">{{menu.liveMenuName}}</div>
            <div class="chat_box" ref="chatContent">
              <div class="user_box" v-for="(item,index) in message" :key="index">
                <div class="head_portrait">
                  <img :src="item.headimgurl" alt="">
                </div>
                <div class="info-box">
                  <div class="user_name">
                    <p>{{item.nickname}}</p>
                  </div>
                  <div class="info">
                    <p>{{item.content}}</p>
                  </div>
                </div>
              </div>
              <!-- <div class="user_box" id="me" v-for="index in 8" :key="index">
                <div class="info-box">
                  <div class="user_name">
                    <p>郭琦(游曳的三文鱼)</p>
                  </div>
                  <div class="info">
                    <p>持续关注中！！！期待！期待！期待都我打排位动物·</p>
                  </div>
                </div>
                <div class="head_portrait">
                  <img src="https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTK4QxCfmx6GtlpojG8fhWWiau7JnkHPakXuwJKUygvNDxavS9tcYXfsOs4qFxA2RrMLFp5fGdoMpKA/132" alt="">
                </div>
              </div> -->
            </div>
            <div class="login" v-show="!isLogin" >
              <p><button @click="login">微信登录</button>后才能参与直播互动</p>
            </div>
            <div class="send_box" v-show="isLogin">
              <input type="text" placeholder="请输入内容" v-model="content" @keyup.enter="sendMessage">
              <button @click="sendMessage">发送</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
    title="扫描二维码登入"
    :visible.sync="dialogVisible"
    >
      <div id="login_container">
        <wx-login :appid="appid" redirect_uri="https://www.sauos.com/re" scope="snsapi_login" :href="qrStyle"></wx-login>
      </div>
      <span slot="footer" class="dialog-footer">
      </span>
    </el-dialog>

    <div v-if="passwordBox" class="passwordBox">
      <div class="centerBox">
        <input v-model="livePassword" placeholder="请输入密码" />
        <el-button type="primary" size="small" @click="subPassword()">确定</el-button>
      </div>
    </div>

  </div>
 
</template>

<script>
import videojs from "video.js";
import "videojs-contrib-hls";
import elementResizeDetectorMaker from "element-resize-detector"
import VueQr from 'vue-qr'
import WxLogin from 'vue-wxlogin'
import {nanoid} from 'nanoid'
  export default {
    name:'LiveRoom',
    data(){
      return{
        livePassword: '', // 密码数据
        passwordBox: false, // 输入密码弹框
        appid:'',
        player:null,
        changeHight:'',
        changWidth:'',
        content:'',
        liveId: this.$route.query.liveId,
        qrData:{
          url:'http://h6.sauos.com/live/'+this.$route.query.liveId,
          icon:'https://img.sauos.com/img/logo.png?x-oss-process=style/wu'
        },
        websock:'',
        live:'',
        pv:null,
        downTime:'',
        liveStream:{},
        kvImg:'',
        time:'',
        menu:'',
        kvShow:false,
        isShow:false,
        timer:'',
        time2:'',
        timer2:'',
        width:'',
        message:[],
        userId:this.$store.state.userId,
        dialogVisible:false,
        isLogin:false,
        chatList:[{id:1,content:'dawiodjwodkwp',class:'row2'},{id:2,content:'dawiodjwodkwp',class:'row1'}],
        // chatList:[],
        qrStyle:'data:text/css;base64,LmltcG93ZXJCb3ggIC50aXRsZXsgZGlzcGxheTpub25lOyB9Ci5pbXBvd2VyQm94ICAuaW5mb3sgZGlzcGxheTpub25lOyB9Ci5pbXBvd2VyQm94IC5xcmNvZGUge3dpZHRoOiAyMDBweDttYXJnaW4tdG9wOiAzNHB4O30KLnN0YXR1c19pY29uIHtkaXNwbGF5OiBub25lfQouaW1wb3dlckJveCAuc3RhdHVzIHt0ZXh0LWFsaWduOiBjZW50ZXI7fQouaWZyYW1lIHt3aWR0aDoyMjBweCAhaW1wb3J0YW50O2hlaWdodDoyMjBweCAhaW1wb3J0YW50O30='
      }
    },
    components:{
      VueQr,
      WxLogin
    },
    mounted() {
      this.initWebSocket()
      this.getVideoInfo()
      this.wacthSize()
      this.getMenu()
      this.getMessage()
      this.cacheUrl()
      this.isCode()
      this.getPv()
    },
    Update(){
      console.log(this.player)
    },
  methods: {
    // 获取pv
    bulletChat(text){
      this.chatList.push({id:nanoid(),class:"row"+Math.floor(Math.random()*3+1),content:text})
      setTimeout(()=>{
        this.chatList.shift()
      },3000)
    },
    getPv(){
      this.$http.get(`/live/log/getLive/${this.liveId}`).then(
          res => {
            const data = res.data
            if(data.code==0){
              if(data.data.pv>=10000){
                this.pv = data.data.pv/10000+'w'
              }else{
                this.pv = data.data.pv
              }
            }
          }
        )
      this.timer2 = setInterval(()=>{
        this.$http.get(`/live/log/getLive/${this.liveId}`).then(
          res => {
            const data = res.data
            if(data.code==0){
              if(data.data.pv>=10000){
                this.pv = data.data.pv/10000+'w'
              }else{
                this.pv = data.data.pv
              }
            }
          }
        )
      },10000) 
    },
    // 时间格式化
    getTime(time) {
      var date = new Date(time * 1000),
        Y = date.getFullYear() + "-",
        M =(date.getMonth() + 1 < 10? "0" + (date.getMonth() + 1): date.getMonth() + 1) + "-",
        D = date.getDate(),
        h = date.getHours(),
        m = date.getMinutes(),
        s = date.getSeconds();
      if (D < 10) {
        D = "0" + D;
      }
      if (h < 10) {
        h = "0" + h;
      }
      if (m < 10) {
        m = "0" + m;
      }
      if (s < 10) {
        s = "0" + s;
      }
       return Y + M + D + " " + h + ":" + m + ":" + s;
      // return Y + M + D
    },
    // 发送消息
    sendMessage(){
      var userInfo =JSON.parse(sessionStorage.getItem('userInfo')) 
      if(this.content == '') return this.$message('内容不能为空')
      this.$http.post('char/sendAll',{
        content:this.content.replace(/[<>&"]/g, function (c) {
          return {
            '<': '&lt;',
            '>': '&gt;',
            '&': '&amp;',
            '"': '&quot;'
          } [c];
        }),
        openId: userInfo.openid,
        nickName: userInfo.nickname,
        headImgUrl: userInfo.headimgurl,
        userId: parseInt(this.userId),
        liveId: parseInt(this.$route.query.liveId),
        realName: userInfo.realName,
        msgType:0
      }).then(
        res =>{
          if(res.data.code==0){
            this.content = ''
          }
        }
      )
    },

// 提交密码
subPassword() {
  // var userInfo =JSON.parse(sessionStorage.getItem('userInfo'))
  // console.log(userInfo)
  this.$http.post('live/pwd/verify', {
    headImgUrl: "",
    liveId: parseInt(this.$route.query.liveId),
    nickName: "",
    openId: "",
    pwd: this.livePassword
  }).then(res => {
    
    if (res.data.code === 0) {
      // 密码正确
      this.getVideoInfoTwo()
      this.passwordBox = false
    } else if (res.data.code === 10008) {
      // 密码错误
      console.log(123123)
      this.$message({
        message: '密码错误',
        type: 'error'
      })
    }
  })
},

    // 是否登入
    isCode(){
      if(sessionStorage.getItem('code')){
        this.isLogin = true
      }
    },
    // code回调地址
    cacheUrl(){
      sessionStorage.setItem('hpurl',window.location.href)
    },
    // 扫码登入
    login(){
      console.log('ok')
      this.$http.get(`user/config/info/${parseInt(this.userId)}`).then(
        response => {
          if(response.data.code == 0){
            this.appid = response.data.userConfig.pcAppid
            this.dialogVisible = true
          }
        },error => {

        }
      )
    },
    // 收到消息返回底部
    handlMessage(){
      let bottom = this.$refs.chatContent.scrollHeight
      this.$refs.chatContent.scrollTop = bottom      
    },
    // 创建webSocket连接
    initWebSocket(){
      const wsuri = 'wss://api.java.sauos.com/ws/asset/'+this.$route.query.liveId
      this.websock = new WebSocket(wsuri);
      this.websock.onmessage = this.websocketonmessage;
      this.websock.onopen = this.websocketonopen;
      this.websock.onerror = this.websocketonerror;
      this.websock.onclose = this.websocketclose;
    },
    //  连接成功
    websocketonopen(){
      console.log('连接成功')
    },
    // 收到消息
    websocketonmessage(e){
      var data = e.data
      data = JSON.parse(data.split('(From Server')[0])  
      if(data){
        console.log(data)
        if(data.liveId == this.$route.query.liveId && data.type==0){
          this.message.push(data)
          this.$nextTick(()=>{
            this.handlMessage()
          })
          if(data.msgType==0){
            this.bulletChat(data.content)
          }
        }else if(data.type==1){
          this.getMessage()
        }
      }
    },
    // 连接失败重新连接
    websocketonerror(){
      this.initWebSocket();
    },
    // 获取历史消息
    getMessage(){
      this.$http.get(`/char/getLiveMessages/${this.$route.query.liveId}`).then(
        response => {
          const data = response.data.data
          this.message = data
        },error=>{

        }
      )
    },
    // 获取聊天菜单
    getMenu(){
      this.$http.get(`live/menu?liveId=${this.$route.query.liveId}&status=1`).then(
        response =>{
          const data = response.data.data
          data.forEach(e => {
            if(e.typeId){
              this.menu = e
              return
            }
          });
        },error =>{

        }
      )
    },
    // 获取直播间信息
    getVideoInfo(){
      this.$http.get(`/live/info/${this.$route.query.liveId}?openId=""`).then(
        response => {
          // console.log(response)
          if (response.data.code === 0) {
            this.live = response.data.data.live
          this.liveStream = response.data.data.liveStream
          document.title = this.live.title
          this.kvImg = this.live.kvImg + this.live.photoStyle
          this.$nextTick(()=>{
            this.getVideo()
          })
          } else if (response.data.code === 10002) {
            // 输入密码观看
            this.passwordBox = true
          }
        },error => {
          // console.log(error)
        }
      )
    },
    // 获取直播间信息无限制
    getVideoInfoTwo(){
      this.$http.get(`/live/infoTwo/${this.$route.query.liveId}?openId=""`).then(
        response => {
          // console.log(response)
          // if (response.data.code === 0) {
            this.live = response.data.data.live
          this.liveStream = response.data.data.liveStream
          document.title = this.live.title
          this.kvImg = this.live.kvImg + this.live.photoStyle
          this.$nextTick(()=>{
            this.getVideo()
          })
          // } else if (response.data.code === 10002) {
          //   // 输入密码观看
          //   this.passwordBox = true
          // }
        },error => {
          // console.log(error)
        }
      )
    },
    // 复制链接
    copyCode(){
      this.$copyText(this.qrData.url).then(
        res=> {
          // this.$toast('复制成功')
          this.$message.success('复制成功')
        },
        err=>{
          // this.$toast('复制失败')
          this.$message.error('复制失败')
        }
      )
    },
    // 二维码鼠标离开
    leaveHidder(){
      this.timer=setTimeout(()=>{
        this.isShow = false
      },1500)
    },
    // 二维码鼠标进入
    enterShow(){
      if(this.timer){
        clearTimeout(this.timer)
      }
      this.isShow = true
    },
    // 自适应播放器
    wacthSize(){
      var erd = elementResizeDetectorMaker()
      erd.listenTo(document.getElementsByClassName("video_box"),  (element) => {
        var width = element.offsetWidth
        var height = element.offsetHeight
        // console.log(height)
        var changeHeight = document.body.clientHeight
        this.changWidth = document.body.clientWidth
        this.$nextTick(function () {
          document.getElementById('myVideo').style="width:100%;height:"+width/16*9+'px'
          if(this.changWidth>991){
            document.getElementById('interaction_box').style="height:"+height+'px'
          }
          if(this.changWidth<991){
            $('#interaction_box').css({"height":changeHeight-height-10})
          }
        })
      })
    },
    // 播放组件
    getVideo() {
      this.player = new videojs("myVideo",{
      })
      this.addDanmaBtn(this.player)
      $('.vjs-poster').css({ "background-image": `url('${this.kvImg}')` })
      var address = [];
      address.push(this.liveStream.liveAddress,this.liveStream.playbackAddress,this.liveStream.defaultAddress)
      address = address.filter((item)=>{
        return item!=null
      })
      console.log(address)
      address = address.map(item=>{
        if(item.indexOf('https')==-1){
          return item.replace('http','https')
        }
        return item
      })
      console.log(address)
      this.player.src(address[0])
      this.player.on('error',()=>{
        address.shift()
        this.player.src(address[0])
        if(!address.length){
          this.kvShow = true
          var timestamp=new Date().getTime()
          this.getCountDown(timestamp)
        }
      })
      if(!address.length){
        this.kvShow = true
        var timestamp=new Date().getTime()
        this.getCountDown(timestamp)
      }
    },
    addDanmaBtn(){
      
    },
    // 倒计时
    getCountDown(time){
      // var downTime = (1631400337602-time)/1000
      var downTime = (this.live.livetime-time)/1000
      if(downTime>0){
        var DD,HH,MM,SS
        this.time2 = setInterval(()=>{
          downTime -= 1
          DD = Math.floor(downTime/60/60/24)
          HH = Math.floor(downTime/60/60%24)
          MM = Math.floor(downTime/60%60)
          SS = Math.floor(downTime%60)
          DD = DD < 10 ? "0"+DD : DD
          HH = HH < 10 ? "0"+HH : HH
          MM = MM < 10 ? "0"+MM : MM
          SS = SS < 10 ? "0"+SS : SS
          this.downTime = DD+'天'+HH+'时'+MM+'分'+SS+'秒'
        },1000)
      }else {
        this.downTime = ''
      }
    }
  },
    beforeCreate () {
      document.querySelector('body').setAttribute('style', 'background:#CCCCCC')
    },
    beforeDestroy () {
      document.querySelector('body').removeAttribute('style')
      this.player.dispose()
      this.websock.close()
      clearInterval(this.time2)
      clearInterval(this.timer2)
    }
  }
</script>

<style lang="less" scope>
  body,html{
    width: 100%;
    height: 100%;
  }
  .video_box{
    background-color: #FFFFFF;
    border-radius: .2em;
  }
  .head_box{
    display: flex;
    padding: 1rem 0;
    align-items: center;
    h4,p{
      margin: 0;
      
    }
    .logo{
      width: 5rem;
      padding: 0 1rem;
      img{
        width: 100%;
      }
    }
    .info_list{
      color: #AD9499;
      font-size: .9rem ;
    }
  }
  .head_box{
    .share{
      padding-right: 2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: .8rem;
      .img_box{
        width: 1.5rem;
        img{
          width: 100%;
        }
      }
    } 
  }
  .no_pl{
    padding-left: 0;
  }
  .interaction{
    background-color: #FFFFFF;
    // height: 100%;
    border-radius: .2em;
    .title{
      color: #006CFF;
      line-height: 2.5rem;
      font-weight: 600;
      font-size: .9rem;
      border-bottom: 1px solid #F1F1F1;
    }
  }
  .page_warp{
    padding-top: 3rem;
    .video_box{
      padding-bottom: 3rem;
    }
  }
  #video-box{
    position: relative;
    .qr{
      position: absolute;
      right: 1%;
      top: 5%;
      z-index: 9999;
      width: 26%;
      border-radius: .2em;
      box-shadow: 0 10px 20px rgb(23 22 49 / 10%);
      background-color: rgb(255, 255, 255);
      .title{
        font-size: .9rem;
        color: #919499;
        margin-top: 1rem;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .qr-box{
        // width: 80%;
        margin: auto;
        margin-top: -1rem;
        img{
          width: 100%;
        }
      }
      .url_box{
        width: 90%;
        margin: auto;
        input{
          border: 1px solid #CCCCCC;
          padding: .2rem .5rem;
          width: 100%;
          border-radius: .3em;
        }
        input:focus{ 
          outline: none;
        }
      }
      .btn_copy{
        border: none;
        background-color: #007bff;
        color: white;
        font-size: .7rem;
        padding: .5rem;
        margin: auto;
        margin-top: .7rem;
        margin-bottom: .7rem;
        display: flex;
        justify-content: center;
        border-radius: .2em;
      }
    }
    .kvImg{
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 999;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .state{
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: rgba(0, 0, 0, .6);
      color: #fff;
      z-index: 1000;
      width: 100%;
      p{
        line-height: 2rem;
        margin: 0;
        padding-left: .5rem;
      }
      span{
        color: #FFAC24;
      }
    }
    .bullet-chat{
      // overflow: hidden;
      padding: .5rem 0;
      width: 100%;
      height: 10rem;
      z-index: 1100;
      position: absolute;
      top: 0;
      left: 0;
      .todo-enter-active{
        animation: move 5s linear;
      }
      @keyframes move{
        from{left: 100%;}
        to{left: -100%;}
      }
      
      .chatBox{
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      p{
        padding: .1rem .4rem;
        color: black;
        border: 1px solid black;
        border-radius: 2em;
        // animation: move 5s linear;
      }
      .row1{
        position: absolute;
        top: 0;
      }
      .row2{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
      .row3{
        position: absolute;
        bottom: 0;
      }
    }
  }
  .chat_box{
    width: 100%;
    height: calc(100% - 6rem);
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 0 5%;
    p{
      margin: 0;
    }
    .user_box{
      width: 100%;
      display: flex;
      padding: .5rem 0;
      .head_portrait{
        img{
          width: 1.7rem;
          height: 1.7rem;
          border-radius: 50%;
        }
      }
      .info-box{
        font-size: .8rem;
        margin-left: .3rem;
      }
      .user_name{
        color: #7b7d80;
      }
      .info{
        padding: .3rem .7rem;
        background-color: #F0F6FF;
        border-radius: .2rem 1em 1em 1em;
        margin-top: .3rem;
        
      }
    }
    #me{
      justify-content: flex-end;
      .info-box{
        margin-left: 0;
        margin-right: .3rem;
      }
      .user_name{
        text-align: right;
      }
      .info{
        border-radius: 1em .2em 1em 1em;
        background-color: #acc8f1;
      }
    }
  }
  .chat_box::-webkit-scrollbar{
    display: none;
  }
  .login{
    margin: 0 5%;
    border: 1px solid #EFEFEF;
    line-height: 2.5rem;
    border-radius: .3em;
    text-align: center;
    font-size: .8rem;
    button{
      border: none;
      background-color: rgba(255, 255, 255, 0);
      padding: 0;
      color: #007bff;
    }
    p{
      margin: 0;
    }
  }
  .send_box{
    margin: 0 8%;
    // border: 1px solid #EFEFEF;
    line-height: 2rem;
    margin-top: .8rem;
    font-size: .5rem;
    input{
      border: 1px solid #EFEFEF;
      width: 80%;
      padding-left: 1rem;
    }
    input:focus{
      outline: none;
    }
    button{
      width: 20%;
      line-height: 2.15rem;
      background-color: #006CFF;
      border: none;
      color: #FFFFFF;
    }
  }
  .el-dialog{
    border-radius: .8em;
    width: 300px;
    height: 320px;
    overflow: hidden;
  }
  .el-dialog__body{
    padding: 0;
  }
  .el-dialog__headerbtn{
    top: 10px;
  }
  .el-dialog__footer{
    padding: 0;
  }
  .el-dialog__header{
    padding: 10px 30px;
    background-color: #F5F6F7;
    .el-dialog__title{
      font-size: 17px;
    }
  }
  .impowerBox{
    .title{
      display: none !important;
    }
  }
  // #login_container{
  //     width: 10rem;
  //   }
  @media screen and (min-width:992px){
    //  body{
    //   background-color: #cccc !important;
    // }
  }
  @media screen and (max-width:991px) {
    .container-lg{
      padding: 0 !important;
    }
    .page_warp{
      padding-top: 0 !important;
      .video_box{
        padding-bottom: 0;
        // position: relative;
      }
      .nogetter{
        padding-right: 0 !important;
        padding-left: 0 !important;
      }
    }
    .qr{
      .title{
        font-size: .7vw !important;
        margin-top: .5rem !important;
      }
    }
    body{
      background-color: #FFFFFF !important;
    }
  }
  @media screen and (min-width:576px) {
    #lk_liveList{
      min-height: 28.5rem;
    }
  }

  .passwordBox {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: #fff;
    z-index: 2000;
  }
  .centerBox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    height: 400px;
  }
  .centerBox input {
    margin-right: 10px;
  }
</style>