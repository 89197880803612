<template>
<div>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="banner_box">
          <img src="http://wwwsauoscom.oss-cn-shanghai.aliyuncs.com/www/images/02wldj/dt.jpg?x-oss-process=style/yilininfo" alt="">
        </div>
        <div id="lk_content">
          <div class="title">
            <h5>益霖信息网络搭建</h5>
          </div>
          <p>
            上海益霖专注于网络搭建的服务，精心的给每一个客户带来良好的回应。益霖公司是一家专业的网络搭建公司，服务于无数大小企业的网络需求，我们只做满意。无论是大型会展还是会小型会场，只有益霖，不容错过，益霖有上好的设备，倾心做好每一次对您的服务！
          </p>
        </div>
      </div>
    </div>
    <div class="title mt-5 mb-3 text-center" >
    <h3><strong>我们的搭建工作</strong></h3>
    </div>
    <div class="row" id="lk_content_two">
      <div class="col-md-6 col-lg-4 img_box mb-4" v-for="(i,index) in imgList" :key="index">
        <div class="img_box">
          <img :src="i.url" alt="">
        </div>
      </div>
    </div>
  </div>
</div> 
</template>

<script>
export default {
  data() {
    return {
      imgList:[
        {url:"http://wwwsauoscom.oss-cn-shanghai.aliyuncs.com/www/images/02wldj/wmddjgz/hcdj02-.jpg?x-oss-process=style/yilininfo"},
        {url:"http://wwwsauoscom.oss-cn-shanghai.aliyuncs.com/www/images/02wldj/wmddjgz/hcdj04-.jpg?x-oss-process=style/yilininfo"},
        {url:"http://wwwsauoscom.oss-cn-shanghai.aliyuncs.com/www/images/02wldj/wmddjgz/hcdj05-.jpeg?x-oss-process=style/yilininfo"}
      ]
    }
  },
}
</script>

<style lang="less" scoped>
  .banner_box{
    margin-top: 6rem;
    img{
      width: 100%;
    }
  }
  #lk_content{
    // width: 80%;
    h5{
      margin: 0;
    }
    .title{
     padding: 20px 0;
     border-bottom: 1px solid #E5E5E5;
    }
    p{
      color: #6E6E6E;
      text-align: justify;
      margin-top: 20px;
    }
  }
  .img_box{
    overflow: hidden;
    img{
      width: 100%;
      transition: all .5s;
    }
    img:hover{
      transform: scale(1.1);
    }
  }
  #lk_content_two{
    margin-bottom: 60px;
  }
</style>