import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    player:'',
    title:'',
    userId: '119',
  },
  mutations: {
    // videoObj(state,value){
    //   state.player = value
    //   console.log(state)
    // }
    Title(state,value){
      state.title = value
    }
  },
  actions: {
    
  },
  modules: {
  }
})
