<template>
  <div id="app">
    <nav-bar v-if="isShow"/>
    <router-view></router-view>
    <lk-footer v-if="isShow"></lk-footer>
    <el-backtop :right="20" :bottom="15">
      <i class="bi bi-chevron-up"></i>
    </el-backtop>
  </div>
</template>

<script>
import NavBar from './views/nav-bar.vue'
import LkFooter from './views/footer.vue'
export default {
  name: 'app',
  data() {
    return {
      
    }
  },
  components: {
    NavBar,
    LkFooter
  },
  mounted() {
    
  },
  computed:{
    isShow(){
      return this.$route.path == '/liveRoom' || this.$route.path == '/re'? false : true
    }
  },
  methods:{
    
  }
}
</script>

<style>
  .el-backtop{
    background-color: rgb(16, 177, 16) !important;
    color: #FFFF !important;
    width: 50px !important;
    height: 50px !important;
  }
</style>
