<template>
  <div id="pageWarpp">
    <div class="container">
      <div class="page_title">
        <h3>联系我们</h3>
      </div>
      <div id="lk_info">
        <div class="row">
          <div class="col-sm-12">
            <div class="info_box">
              <p><i class="bi bi-tablet-fill"></i>181-2136-3226</p>
              <p><i class="bi bi-envelope-fill"></i>vip@yilininfo.com</p>
              <p><i class="bi bi-geo-alt-fill"></i>上海市浦东新区川宏路508号309</p>
            </div>
          </div>
        </div>  
      </div>
      <div id="lk_map">
        <div class="row">
          <div class="col-sm-12">
            <el-amap class="amap-box"  :zoom="zoom" :center="center" :vid="'amap-vue'" style="width:100%;height:'500px;'">
               <el-amap-marker vid="marker" :position="center" :label="label">

               </el-amap-marker>
            </el-amap>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:'Contact',
 data(){
    return {
      center: [121.71160,31.159400],
      zoom:16,
      mapStyle:'dark',
      label:{
        content:'上海益霖信息科技有限公司',
        offset:[20,12]
      }  
    }
  }
}
</script>

<style lang="less" scoped>
  #pageWarpp{
    margin-top: 150px;
    h3{
      margin-bottom: 2rem;
    }
  }
  .amap-box{
    width: 100%;
    height: 40rem;
    box-shadow: 0 0 10px grey;;
  }
  #lk_info{
    .info_box{
      p{
        margin-bottom: 2rem;
        font-size: 18px;
      }
      i{
        color: rgba(97, 96, 96, 0.8);
        margin-right: .8rem;
      }
    }
  }
  #lk_map{
    margin-bottom: 5rem;
  }
</style>