<template>
  <nav class="navbar navbar-expand-lg navbar-light navbar-bac box-shadow fixed-top">
    <div class="container col-xl-10">
      <a class="navbar-brand" href="#">
        <img src="http://wwwsauoscom.oss-cn-shanghai.aliyuncs.com/www/images/img/LOGO-03-04.png?x-oss-process=style/yilininfo" alt="">
      </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav text-center">
          <li class="nav-item ">
            <router-link class="nav-link" to="home" active-class="active">首页<span class="sr-only">(current)</span></router-link>
          </li>
          <li class="nav-item ">
            <router-link class="nav-link" to="network" active-class="active">网络搭建</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="customized" active-class="active">H5定制开发</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="service" active-class="active">直播服务</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="convention" active-class="active">大数据会展</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="liveList" active-class="active">视频直播</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="case" active-class="active">活动案例</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="problem" active-class="active">常见问题</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="contact" active-class="active">联系我们</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
 
</template>

<script>
  export default {
    name:'nav-bar'
    
  }
</script>
  
<style lang="less" scope>
  .box-shadow{
    box-shadow: 0px 0px 5px #807f7f;
  }
  .nav-item{
    height: 2rem !important;
  }
  .nav-link{
    color: #6E6E6E;
    padding: 0 !important;
  }
  .active{
    color: #1E8BFF !important;
  }
  // .navbar{
  //   height: 90px;
  // }
  .navbar-bac{
    background-color: rgba(255,255,255,.9);
  }
 .navbar .navbar-brand img{
    width: 200px;
  }
  .navbar-collapse{
    flex-grow: 0 !important;
  }
  .navbar-nav{
    .nav-item{
      a{
        margin-left: 30px;
      }
    }
  }
</style>