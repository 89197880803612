<template>
<div>
  <header>
    <div class="bd-example bd-martop">
      <div id="carouselExampleCaptions" class="carousel slide" data-ride="carousel">
        <ol class="carousel-indicators">
          <li data-target="#carouselExampleCaptions" data-slide-to="0" class="active"></li>
          <li data-target="#carouselExampleCaptions" data-slide-to="1"></li>
          <!-- <li data-target="#carouselExampleCaptions" data-slide-to="2"></li> -->
        </ol>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src="http://wwwsauoscom.oss-cn-shanghai.aliyuncs.com/www/images/01sy/gwlbdt.jpg?x-oss-process=style/yilininfo" class="d-block w-100" alt="">
            <div class="carousel-caption d-none d-md-block">
              <!-- <h5>First slide label</h5>
              <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> -->
            </div>
          </div>
          <div class="carousel-item">
            <img src="http://wwwsauoscom.oss-cn-shanghai.aliyuncs.com/www/images/01sy/sydt02.jpg?x-oss-process=style/yilininfo" class="d-block w-100" alt="">
            <div class="carousel-caption d-none d-md-block">
              <!-- <h5>Second slide label</h5>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> -->
            </div>
          </div>
          <!-- <div class="carousel-item">
            <img src="" class="d-block w-100" alt="">
            <div class="carousel-caption d-none d-md-block">
              <h5>Third slide label</h5>
              <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
            </div>
          </div> -->
        </div>
        <a class="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselExampleCaptions" role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
    </div>
  </header>

  <main>
    <section>
      <div class="text-center mt-5 mb-5" >
        <h2><strong>服务范围</strong></h2>
        <p class="text-p text-color">您的满意是我们最大的追求</p>
      </div>
      <div class="container">
        <div class="row text-center clear_margin">
          <div class="col-12 col-md-6 col-lg-4 isoCol vintage descriptionWrap box-boder"  id="lk_content_header" v-for="(item,index) in list_one" :key="index">
            <h4 class="mb-3">{{item.title}}</h4>
            <p class="text-color">{{item.content}}</p>
            <div class="img-box" :style="`background:url(${item.imgurl}) no-repeat;background-size:100% 100%;`">
               
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="text-center mt-5 mb-5" >
        <h2><strong>自主开发核心产品</strong></h2>
        <p class="text-p">我们10年峰尖研发团队匠心之作</p>
      </div>
      <div class="container " id="lk-content">
        <div class="row">
          <div class="col-sm-6 col-lg-4 descriptionWrap" v-for="(item,index) in list_two" :key="index">
            <div class="img-box mb-4">
              <img :src="item.imgurl" alt="">
            </div>
            <div class="txt-box">
              <h4>{{item.title}}</h4>
              <p class="text-color">{{item.content}}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="mt-5 bac_color" id="lk-content_one">
        <div class="container">
          <div class="row">
            <div class="img-box col-lg-6">
              <img src="http://wwwsauoscom.oss-cn-shanghai.aliyuncs.com/www/images/img/about-img.jpg?x-oss-process=style/yilininfo" alt="">
            </div>
            <div class="txt-box col-lg-6">
              <h3 class="mb-4"><strong>公司介绍</strong></h3>
              <p class="text-justify">上海益霖信息科技有限公司（简称益霖）是国内会展科技服务提供商，总部位于上海市浦东新区川宏路508号309，注册资金2000万元</p>
              <p class="text-color text-justify">是以人工智能、大数据等高科技行业会展服务的专业企业集团。益霖专注于会展行业科技产品研发与服务，专业提供会展行业的宽带网络搭建、会展大数据、一站式商务视频直播等科技服务。益霖已为多家企业客户提供过技术服务，涵盖大型展览、会议、活动、发布会、音乐节、科技节、年会、直播等，极致的用户体验和贴心的一站式服务得到客户的高度认可。</p>
              <hr class="separator mt-5"> 
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div id="lk-content_two">
        <div class="container mt-5 mb-5">
          <h2><strong>近期案例</strong></h2>
          <p class="text-warning"><router-link to="case"><strong>了解更多</strong></router-link></p>
          <div class="row mt-5">
            <div class="col-12 col-md-6 col-lg-4 isoCol vintage  item_list mb-4" v-for="item in caseList" :key="item.caseId">
              <div class="img-box position-relative">
                <img :src="item.caseKv" alt="">
                <div class="info_content bac_color_info text-justify">
                  <h6><router-link to="case">{{item.caseTitle}}</router-link></h6>
                  <p>{{item.caseMessage}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</div>

</template>

<script>
  export default {
    name:'Home',
    data() {
      return {
        caseList:[],
        userId:this.$store.state.userId,
        list_one:[
          { title:'网络搭建',imgurl:'http://wwwsauoscom.oss-cn-shanghai.aliyuncs.com/www/images/01sy/01fwfw/01hcWIFI.jpg?x-oss-process=style/yilininfo',content:'直播网络、会议网络、年会WiFi、发布会WiFi、直播WiFi、体育赛事WiFi、教育培训WiFi'},
          { title:'H5定制开发',imgurl:'http://wwwsauoscom.oss-cn-shanghai.aliyuncs.com/www/images/01sy/01fwfw/H5dzkf-04.jpg?x-oss-process=style/yilininfo',content:'霖H5制作平台拥有强大的定制开发技术团队，提供最潮流的H5玩法，最新颖的H5形式！'},
          { title:'直播',imgurl:'http://wwwsauoscom.oss-cn-shanghai.aliyuncs.com/www/images/01sy/01fwfw/03zb.jpg?x-oss-process=style/yilininfo',content:'直可同时满足录播与直播功能，自带数据传输通道，同步实现视频流的传输，满足会议室的同步接收，达到视觉的良好效果， 高清画质与清晰音质'},
          { title:'视频拍摄制作',imgurl:'http://wwwsauoscom.oss-cn-shanghai.aliyuncs.com/www/images/01sy/01fwfw/04spjjzz.jpg?x-oss-process=style/yilininfo',content:'直可同时满足录播与直播功能，自带数据传输通道，同步实现视频流的传输，满足会议室的同步接收，达到视觉的良好效果， 高清画质与清晰音质'},
          { title:'会展大数据',imgurl:'http://wwwsauoscom.oss-cn-shanghai.aliyuncs.com/www/images/01sy/01fwfw/05hzdsj.jpg?x-oss-process=style/yilininfo',content:'会展客户流分析、数据大屏展示、客流规模分析等。提供更精准了解用户消费行为、兴趣偏好等特征的数据依据。细分核心用户的行为分析，制定运营与营销方案'},
          { title:'企业云服务',imgurl:'http://wwwsauoscom.oss-cn-shanghai.aliyuncs.com/www/images/01sy/01fwfw/06qyyfw.jpg?x-oss-process=style/yilininfo',content:'云计算，网络存储，虚拟化，负载均衡，内容分发网络等。为您提供安全性更高，稳定性更高的机房'},
        ],
        list_two:[
          {title:'WIFI基站',imgurl:'http://wwwsauoscom.oss-cn-shanghai.aliyuncs.com/www/images/01sy/04hxcp/WiFijz-01.jpg?x-oss-process=style/yilininfo',content:'上市7年我们二代产品于2020-10-1上市，全国代理商招募中'},
          {title:'直播导播载波网络一体机',imgurl:'http://wwwsauoscom.oss-cn-shanghai.aliyuncs.com/www/images/01sy/04hxcp/zbytj.jpg?x-oss-process=style/yilininfo',content:'现场无需专业技术人员值守，让活动会议直播变得更简单，全国代理商招募中'},
          {title:'远程导播/超长延时直播',imgurl:'http://wwwsauoscom.oss-cn-shanghai.aliyuncs.com/www/images/01sy/04hxcp/zbtl.jpg?x-oss-process=style/yilininfo',content:'让直播变得更加安全，您的直播安全，我们为您分忧'},
        ]
      }
    },
    mounted() {
      this.getList()
    },
    methods: {
      getList(){
        this.$http.post(`/case/getCaseUser`,{userId:this.userId}).then(
          response => {
            var data = response.data
            if(data.code==0){
              var list = response.data.page.list
              this.newList(list)
            }
          },error => {

          }
        )
      },
      newList(list){
        for(var i =0;i<6;i++){
          if(list[i]){
            this.caseList.push(list[i])
          }
        }
      }
    },
  }
</script>

<style lang='less' scope>
  .bd-martop{
    margin-top: 90px;
  }
  // p{letter-spacing:1px}
  .text-justify{
    text-align: justify;
  }
  .text-p{
    font-size: 20px;
  }
  .text-color{
    color: #6E6E6E;
  }
  .box-boder{
    border-bottom: 1px solid #E5E5E5;
    border-right: 1px solid #E5E5E5;
  }
  .box-boder:nth-of-type(3n){
    border-right: none;
  }
  @media screen and (max-width: 991px){
    .box-boder:nth-of-type(odd){
      border-right: 1px solid #E5E5E5;
    }
    .box-boder:nth-of-type(even){
      border-right: none;
    }
    
  }
  @media screen and (max-width:768px) {
    .box-boder{
      border: none !important;
    }
  }
  @media screen and (max-width: 768px) and (min-width: 430px){
    #lk_content_header{
      padding: 70px 30px !important;
    }
  }
  @media (max-width: 575px) {
    .clear_margin{
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
  @media screen and (min-width: 1200px) {
    #kl_footer{
      img{
        width: 70% !important;
        text-align: right !important;
      }
    }
  }
  #lk_content_header{
    position: relative;
    .img-box{
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      transform: translate(-50%,-50%) scale(1.05);
      opacity: 0;
      background-size: 100% 100%;
      // transform: scale(1.1);
      // transition: transform .7s;
      transition: all .5s;
    }
    .img-box:hover{
      transform: translate(-50%,-50%) scale(1);
      opacity: 1;
    }
  }
 .descriptionWrap{
    padding: 50px 30px !important;
  }
 
  #lk-content{
    .descriptionWrap{
      padding: 70px 20px 20px !important;
    }
    .img-box{
      img{
        width: 100%;
        transition: transform .2s;
      }
      img:hover{
        transform: scale(1.04);
      }
    } 
  } 
  .bac_color{
    background-color: #F2F4F5;
  }
  #lk-content_one{
    padding: 70px 0 40px;
    .img-box{
      padding-bottom: 30px;
      img{
        width: 100%;
      }
    }
  }
  #lk-content_two{
    padding: 70px 0;
    .text-warning {
      a{
        color: #FFBD54 !important;
      }
      a:hover{
        text-decoration: none;
        color: rgb(32, 101, 204) !important;
      }
    }
    .item_list{
      .img-box{
        overflow: hidden;
        img{
          width: 115%;
          height: 13rem;
          transform: scale(1.1);
          transition: transform .2s;
        }
      }
      .img-box:hover{
        img{
          transform: scale(1.3);
        }
      }
    }
  }
  #lk-content_two{
    .item_list{
      .bac_color_info{
        background-color: rgba(255, 255, 255, .8);
      }
      .info_content{
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%,100%);
        width: 90%;
        padding: 10px 10px 25px;
        transition:  transform .2s;
        // pointer-events: none;
        p,h6{
          margin: 0;
          cursor: pointer;
        }
        a{
          color: black !important;
        }
        p{
          font-size: 14px;
        }
        a:hover{
          color: #FFBD54 !important;
          text-decoration: none;
        }
      }
      .img-box:hover{
        .info_content{
          transform: translate(-50%,-30%);
        }
      }
      @media screen and(max-width: none){
        
      }
    }
  }
  


</style>