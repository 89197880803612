<template>
  <footer>
    <div class="container col-xl-10" id="kl_footer">
      <div class="info_box row justify-content-center">
        <ul class="col-sm-8 col-xs-6 col-md-9 col-lg-10 col-xl-10" style="list-style:none;">
          <li>
            <p><i class="bi bi-house-fill mr-2"></i>
            <strong>上海益霖信息科技有限公司</strong> 
            </p>
          </li>
          <li>
            <p><i class="bi bi-telephone-fill mr-2"></i>
            181-2136-3226
            </p>
          </li>
          <li>
            <p><i class="bi bi-envelope-fill mr-2"></i>
            vip@yilininfo.com
            </p>
          </li>
          <li>
            <p><i class="bi bi-cursor-fill mr-2"></i>
            上海市浦东新区川宏路508号309
            </p>
          </li>
          <li>
            <p>
              <a style="color: #A6A6A6;" href="https://beian.miit.gov.cn/">
              沪ICP备13042431号-4
              </a>
            </p>
          </li>
        </ul>
        <div class="contact col-lg-2 col-sm-4 col-xs-6 col-md-3 col-xl-2 col-5 text-center ">
          <img src="http://wwwsauoscom.oss-cn-shanghai.aliyuncs.com/www/images/img/ewm.png" alt="" style="width:100%;">
          <p>微信扫一扫加我好友</p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name:'FooterPath'
}
</script>

<style lang='less' >
  footer{
    width: 100%;
    padding: 15px 0 20px;
    background-color: #303030;
    color: #A6A6A6;
  }
  .strong{
    font-weight: 900;
  }
</style>