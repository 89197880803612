import Vue from 'vue'
import Router from 'vue-router'
import Home from '../views/home'
import LiveList from '../views/live-list'
import Network from '../views/network'
import ActivityCase from '../views/activity-case'
import LiveRoom from '../views/live-room'
import Customized from '../views/customized'
import Service from '../views/service'
import Contact from '../views/contact'
import Problem from '../views/problem'
import Convention from '../views/convention'
import Re from '../views/re'
Vue.use(Router)

const router = new Router({
  mode: 'history',
  // base:'/test/',
  routes: [
    { path:'/',redirect:'home' },
    { path:'/home',name:'home',component: Home,meta:{title:'首页'}},
    { path:'/liveList',name:'liveList',component:LiveList,meta:{title:'视频直播'}},
    { path:'/case',name:'case',component:ActivityCase,meta:{title:'活动案例'}},
    { path:'/liveRoom',name:'liveRoom',component:LiveRoom},
    { path:'/network',name:'network',component:Network,meta:{title:'网络搭建'}},
    { path:'/customized',name:'customized',component:Customized,meta:{title:'H5定制开发'}},
    { path:'/service',name:'service',component:Service,meta:{title:'直播服务'}},
    { path:'/contact',name:'contact',component:Contact,meta:{title:'联系我们'}},
    { path:'/problem',name:'problem',component:Problem,meta:{title:'常见问题'}},
    { path:'/convention',name:'convention',component:Convention,meta:{title:'大数据会展'}},
    { path:'/re',name:'re',component:Re,meta:{title:'授权中...'}}
  ],
})
router.afterEach((to,from) => {
  if(to.name!='liveRoom'){
    document.title = to.meta.title
  }
})
export default router