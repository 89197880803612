<template>
  <div>
    <header class="container" id="lk_header">
      <div class="row justify-content-center">
        <div class="search_box col-10 col-sm-10 col-md-7 ">
          <p>
            <input type="text" placeholder="请输入直播标题" v-model="title" @keydown.enter="getLiveList(1)">
            <button @click="getLiveList(1)">搜索</button>
          </p>
        </div>
      </div>
    </header>

    <main>
      <div class="container  col-sm-11 col-xl-8 mt-5">
        <div class="row mt-4" id="lk_liveList">
          <div class="list_item col-sm-6 col-md-6 col-lg-4 mb-5" v-for="item in liveList" :key="item.liveId" @click="linkliveRoom(item)">
            <div class="live_box">
              <div class="kv_box">
                <img :src="item.kvImg" alt="">
              </div>
              <div class="info_box p-2">
                <h5 class="rmb title"><strong>{{item.title}}</strong></h5>
                <div class="remarks txt-color">{{item.liveinfo}}</div>
                <!-- <p class="remarks txt-color"></p> -->
                <div class="d-flex justify-content-center txt-color">
                  <div class="mr-auto">{{getTime(item.livetime)}}</div>
                  <div>上海</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
  export default {
    name:'LiveList',
    data() {
      return {
        warpWidth:document.documentElement.clientWidth/100,
        liveList:[],
        title:'',
        userId:this.$store.state.userId,
      }
    },
    computed:{
     
    },
    mounted() {
      this.getLiveList()
    },
    methods: {
      getTime(time) {
        var date = new Date(time * 1000),
        Y = date.getFullYear() + "-",
        M =(date.getMonth() + 1 < 10? "0" + (date.getMonth() + 1): date.getMonth() + 1) + "-",
        D = date.getDate(),
        h = date.getHours(),
        m = date.getMinutes(),
        s = date.getSeconds();
        if (D < 10) {
          D = "0" + D;
        }
        if (h < 10) {
          h = "0" + h;
        }
        if (m < 10) {
          m = "0" + m;
        }
        if (s < 10) {
          s = "0" + s;
        }
        //  return Y + M + D + " " + h + ":" + m + ":" + s;
        return Y + M + D
      },
      getLiveList(type){
        this.$http.post('live/user/list',{userId:this.userId,liveType:'0',key:type?this.title:''}).then(
          response =>{
            if(response.data.code === 0){
              const list = response.data.page.list
              this.liveList = list
            }
          },
          error=>{

          }
        )
      },
      linkliveRoom(m){
        this.$router.push({
          name:'liveRoom',
          query:{
            liveId:m.liveId
          }
        })
      }
    },
  }
</script>

<style lang="less" scope>
  #lk_header{
    padding-top: 150px;
    text-align: center;
    .search_box{
      input{
        padding: 12px 15px;
        border: 1px solid #4F91FF;
        border-right: none;
        border-top-left-radius: .5em;
        border-bottom-left-radius: .5em;
        width: 80%;
      }
      input:focus{ 
        outline: none;
      }
      button{
        border: none;
        padding: 13px 0;
        width: 20%;
        background-color: #4F91FF;
        color: #FFFFFF;
        border-top-right-radius: .5em;
        border-bottom-right-radius: .5em;
      }
    }
  }
  #lk_liveList{
    a{
      text-decoration: none;
      color: black;
    }
    .list_item{
      cursor: pointer;
    }
    a:hover{
      color: #409EFF;
    }
    .rmb{
      margin-bottom: 1rem;
    }
    .live_box{
      overflow: hidden;
      border-radius: .5em;
      box-shadow: .2rem 0.2rem 0.8rem #d1d1d1;
      .kv_box{
        height: 13rem;
        overflow: hidden;
        img{
          width: 100%;
          height: 100%;
          transition: all .5s;
        }
        img:hover{
          transform: scale(1.2);
        }
      }
      .title{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
      }
      .remarks{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
        width: 60%;
      }
      .txt-color{
        color: #B59B9B;
      }
    }
  }
  @media screen and (max-width: 767px) {
    #lk_header{
      input{
        padding: 8px 15px !important;
      }
      button{
        padding: 9px 0 !important;
      }
    }
  }
  @media screen and (max-width:1350px) and (min-width:1200px){
    #lk_liveList{
      .kv_box{
        height: 11rem !important;
      }
    }
  }
  @media screen and (max-width:730px) and (min-width:576px){
    #lk_liveList{
      .kv_box{
        height: 9rem !important;
      }
    }
  }
</style>