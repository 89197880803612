<template>
<div id="pagewarpp">
  <div class="container">
    <div class="title text-center">
      <h2><strong>直播数据分析</strong></h2>
    </div>
    <div class="" id="lk-content_one">
      <div class="row">
        <div class="col-md-6 mb-5">
          <div class="title">
            <p>在线人数</p>
          </div>
          <div class="img_box hover">
            <img :src="`${url}data1.jpg?x-oss-process=style/wu`" alt="">
          </div>
        </div>
        <div class="col-md-6 mb-5">
          <div class="title">
            <p>观看时长分布</p>
          </div>
          <div class="img_box hover">
            <img :src="`${url}data2.jpg?x-oss-process=style/wu`" alt="">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 mb-5">
          <div class="title">
            <p>访客榜TOP10</p>
          </div>
          <div class="img_box hover">
            <img :src="`${url}data3.jpg?x-oss-process=style/wu`" alt="">
          </div>
        </div>  
        <div class="col-md-3 mb-5">
          <div class="title">
            <p>各访客移动设备分布</p>
          </div>
          <div class="img_box img-special hover">
            <img :src="`${url}data6.jpg?x-oss-process=style/wu`" alt="">
          </div>
        </div>
        <div class="col-md-3 mb-5">
          <div class="title">
            <p>各访客观看终端来源</p>
          </div>
          <div class="img_box img-special hover">
            <img :src="`${url}data5.jpg?x-oss-process=style/wu`" alt="">
          </div>
        </div>
      </div>
    </div>
    <div id="lk-content_two">
      <div class="row justify-content-center">
        <div class="col-md-4 mb-5" v-for="(i,index) in imgList" :key="index">
          <div class="img_box">
            <div class="hover">
              <img :src="i.url" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="lk-content_three" class="mb-5">
    <div class="mask"></div>
    <div class="parallax" :style="{'background-position-y':positionY1+'px'}">
      <div class="container">
        <div class="row">
          <div class="col-md-6 title">
            <h3><strong>优化我们的直播服务</strong></h3>
            <p>质量是我们的追求，我们知道我们在做什么</p>
          </div>
          <div class="col-md-6 phone">
            <div class="box_3D">
              <button :style="'transform:translateZ('+height+'px);'"><h5><strong><i class="bi bi-telephone-fill"></i>  18121363226</strong></h5></button>
              <button :style="'transform:rotateX(-90deg) translateZ('+height+'px);'" style="border:none;"><h5><strong><i class="bi bi-telephone-fill"></i>  18121363226</strong></h5></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <!-- <div style="height:3000px"></div> -->
  <div class="container" id="lk-content_four">
    <div class="container">
      <div class="title text-center mb-5">
        <h2><strong>我们的直播范围</strong></h2>
      </div>
      <div class="row">
        <div class="col-md-4 mb-5" v-for="(item,index) in list_one " :key="index">
          <div class="shadow">
            <div class="header text-center">
              <h5><strong>{{item.title}}</strong></h5>
            </div>
            <div class="main">
              <p>{{item.content}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container" id="lk-content_four">
    <div class="container">
      <div class="title text-center mb-5">
        <h2><strong>我们的产品与服务优势</strong></h2>
      </div>
      <div class="row">
        <div class="col-md-4 mb-5" v-for="(item,index) in list_two " :key="index">
          <div class="shadow">
            <div class="header text-center">
              <h5><strong>{{item.title}}</strong></h5>
            </div>
            <div class="main">
              <p>{{item.content}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <section>
    <div id="lk-content_five">
      <div class="container mt-5 mb-5">
        <h2><strong>近期案例</strong></h2>
        <p class="text-warning"><router-link to="case"><strong>了解更多</strong></router-link></p>
        <div class="row mt-5">
          <div class="col-12 col-md-6 col-lg-4 isoCol vintage  item_list mb-4" v-for="item in caseList" :key="item.caseId">
            <div class="img-box position-relative">
              <img :src="item.caseKv" alt="">
              <div class="info_content bac_color_info text-justify">
                <h6><router-link to="case">{{item.caseTitle}}</router-link></h6>
                <p>{{item.caseMessage}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
</template>

<script>
import elementResizeDetectorMaker from "element-resize-detector"
export default {
  data() {
    return {
      url:'https://img.sauos.com/hh/yilin/',
      imgList:[
        {url:'http://wwwsauoscom.oss-cn-shanghai.aliyuncs.com/www/images/04zbfw/dybf/2020shzxgzz2.jpg?x-oss-process=style/yilininfo'},
        {url:'http://wwwsauoscom.oss-cn-shanghai.aliyuncs.com/www/images/04zbfw/dybf/ypzbxc.jpg?x-oss-process=style/yilininfo'},
        {url:'http://wwwsauoscom.oss-cn-shanghai.aliyuncs.com/www/images/04zbfw/dybf/zbxc.jpg?x-oss-process=style/yilininfo'}
      ],
      Y1:0,
      ratio:.3,
      positionY1:0,
      userId:this.$store.state.userId,
      height:'',
      caseList:[],
      list_one:[
        { title:'异地连线',content:'跨越时间、地域、国界、人数等等限制，异地连线技术可以将不同地点的画面及声音同步到同一个直播页面，让直播的形式多样化，直播内容更加丰富多彩，直播的传播效果得到进一步提升！' },
        { title:'多平台直播',content:'天猫直播、京东直播、淘宝直播、抖音直播、快手直播、虎牙直播、活动直播' },
        { title:'虚拟直播',content:'多机位直播抠像系统，实时抠像，专业信号采集实时稳定无延迟。虚拟直播颠覆了传统影视制作前期、后期衔接成本和周期太长的流程痛点，一站式的虚拟预演拍摄手段直接给剧组提供可视化的接近成片效果。' }
      ],
      list_two:[
        { title:'个性化定制',content:'观看人数/时长/流量无上限红包/打赏/问卷/投票等互动均具备界面/logo/背景/菜单均可定制' },
        { title:'一站式服务',content:'我们提供：网络、直播、导播、摄像一站式服务我们提供：国内最稳定、最大的企业直播平台我们提供：国内最专业的执行团队' },
        { title:'合理报价',content:'服务标准规范，服务团队专业统一规范报价，综合性价比高' }
      ]
    }
  },
  mounted() {
    window.addEventListener('scroll',this.handleScroll)
    let parallax = $('.parallax')
    this.Y1 =  Math.abs(parallax.offset().top*this.ratio)
    this.wacthSize()
    this.getList()
  },
  watch:{
    
  },
  methods: {
     getList(){
      this.$http.post(`/case/getCaseUser`,{userId:this.userId}).then(
        response => {
          var data = response.data
          if(data.code==0){
            var list = response.data.page.list
            this.newList(list)
          }
        },error => {

        }
      )
    },
    newList(list){
      for(var i =0;i<3;i++){
        if(list[i]){
          this.caseList.push(list[i])
        }
      }
    },
    wacthSize(){
      var erd = elementResizeDetectorMaker()
      erd.listenTo($(".box_3D button").eq(1),(element) => {
        var width = element.offsetWidth
        var height = element.offsetHeight
        this.$nextTick(()=>{
          this.height = height/2
          $('.box_3D').css({'height':height+'px','width':width+'px'})
        })
      })
    },
    handleScroll: function() {
      let scrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop;
      this.positionY1 = Math.abs(this.Y1 - scrollTop * this.ratio)
    }
  },
}
</script>

<style lang="less" scope>
  #pagewarpp{
    margin-top: 150px;
  }
  .hover{
    transition: all .3s;
  }
  .hover:hover{
    transform: scale(1.1);
  } 
  .shoadow{
    box-shadow: 0 10px 20px rgb(23 22 49 / 10%);
  }
  #lk-content_one{
    // margin-bottom: px;
    .title{
      color: #6E6E6E;
    }
    .img_box{
      height: 16rem;
      margin: 0;
      overflow: inherit;
      border: 1px solid #EEEEEE;
      img{
        width:100% ;
        height: 100%;
      }
    }
    .img_box:hover{
      border-left: none;
      border-right: none;
    }
  }
  #lk-content_two{
    padding: 0;
    .img_box{
      height: 30rem;
      overflow: hidden;
      img{
        width: 100%;
      }
    }
  }
  #lk-content_three{
    position: relative;
    .mask{
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      background-color: rgba(0, 0, 0, 0.5);
    }
    .parallax{
      background: url(http://wwwsauoscom.oss-cn-shanghai.aliyuncs.com/www/images/04zbfw/dybf/lmzb01.jpg?x-oss-process=style/yilininfo) no-repeat;
      height: 12rem;
      background-attachment: fixed;
		  background-size: 100% 100%;
      color: #FFFFFF;
      .container{
        height: 100%;
        .row{
          height: 100%;
        }
      }
      p{
        margin: 0;
      }
      .title{
        width: 100%;
        display: flex;
        z-index: 999;
        flex-direction: column;
        justify-content: center;
      }
      .phone{
        z-index: 999;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .box_3D{
          transform-style: preserve-3d;
          position: relative;
        }
        button{
          border: 1px solid #FFFFFF;
          background-color: inherit;
          color: #FFFFFF;
          padding: 10px 20px;
          border-radius: 2em;
          position: absolute;
          left: 0;
          top: 0;
          h5{
            margin: 0;
            white-space:nowrap;
          }
        }
        .box_3D{
          transition: all .3s;
        }
        .box_3D:hover{
          transform: rotateX(90deg);
        }
      }
    }
  }
  #lk-content_four{
    margin-bottom: 80px;
    .header{
      padding: 40px 0;
      background-color: #F2F4F5;
    }
    .main{
      text-align: center;
      padding: 5rem 1rem 0;
      min-height: 23rem;
      color: #6E6E6E;
    }
  }
  #lk-content_five{
    .text-warning {
      a{
        color: #FFBD54 !important;
      }
      a:hover{
        text-decoration: none;
        color: rgb(32, 101, 204) !important;
      }
    }
    .item_list{
      .img-box{
        overflow: hidden;
        img{
          width: 115%;
          height: 13rem;
          transform: scale(1.1);
          transition: transform .2s;
        }
      }
      .img-box:hover{
        img{
          transform: scale(1.3);
        }
      }
    }
  }
  #lk-content_five{
    .item_list{
      .bac_color_info{
        background-color: rgba(255, 255, 255, .8);
      }
      .info_content{
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%,100%);
        width: 90%;
        padding: 10px 10px 25px;
        transition:  transform .2s;
        // pointer-events: none;
        p,h6{
          margin: 0;
          cursor: pointer;
        }
        a{
          color: black !important;
        }
        p{
          font-size: 14px;
        }
        a:hover{
          color: #FFBD54 !important;
          text-decoration: none;
        }
      }
      .img-box:hover{
        .info_content{
          transform: translate(-50%,-30%);
        }
      }
      @media screen and(max-width: none){
        
      }
    }
  }
  @media screen and (min-width:1200px) {
    
  }
  @media screen and (max-width:1199px) and (min-width:992px){
    #lk-content_two{
      .img_box{
        height: 24rem;
      }
    }
  }
  @media screen and (max-width:991px) and (min-width:768px) {
    #lk-content_one{
      .img_box{
        height: 12rem !important;
      }
    }
    #lk-content_two{
      .img_box{
        height: 19rem;
      }
    }
  }
  @media screen and (max-width:767px) {
    .img-special{
      overflow: hidden !important;
      position: relative;
     img{
       width: 100%;
       height: auto !important;
       position: absolute;
       top: 50%;
       left: 50%;
       transform: translate(-50%,-50%);
     }
    }
    #lk-content_two{
      .img_box{
        height: 17rem;
        img{
          transform: translateY(-34%);
        }
      }
    }
    #lk-content_three{
      text-align: center;
      .parallax{
        height: 15rem;
      }
      .title{
        h3{
          font-size: 22px;
        }
        justify-content: flex-end !important;
      }
      .phone{
        justify-content: center !important;
        align-items: flex-start !important;
        margin-top: 10px;
      }
    }
  }
</style>