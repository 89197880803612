<template>
  <div class="pageWarpp">
    <div class="container">
      <div class="page_title text-center">
        <h3><strong>益霖会展大数据分析</strong></h3>
        <p>以大数据为基础的多维统计和对比统计，使会展统计更加系统化、科学化</p>
      </div>
      <div id="lk-content-one">
        <div class="row">
          <div class="col-md-6 col-lg-4">
            <div class="modular_box">
              <div class="img_box">
                <img class="hover" src="http://wwwsauoscom.oss-cn-shanghai.aliyuncs.com/www/images/03hzdsj/dybf/hzdsj03.jpg?x-oss-process=style/yilininfo" alt="">
              </div>
              <div class="title">
                <h5>数据抓取</h5>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="modular_box">
              <div class="img_box">
                <img class="hover" src="http://wwwsauoscom.oss-cn-shanghai.aliyuncs.com/www/images/03hzdsj/dybf/hzdsj04.jpg?x-oss-process=style/yilininfo" alt="">
              </div>
              <div class="title">
                <h5>数据分析</h5>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="modular_box">
              <div class="img_box">
                <img class="hover" src="http://wwwsauoscom.oss-cn-shanghai.aliyuncs.com/www/images/03hzdsj/dybf/hzdsj05.jpg?x-oss-process=style/yilininfo" alt="">
              </div>
              <div class="title">
                <h5>数据统计</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="lk-content-two">
        <div class="row mb-5" v-show="isShow">
          <div class="col-md-6">
            <div class="img-box">
              <img src="http://wwwsauoscom.oss-cn-shanghai.aliyuncs.com/www/images/03hzdsj/debf/hzdsj01-02.jpg?x-oss-process=style/yilininfo" alt="">
            </div>
          </div>
          <div class="col-md-6">
            <div class="title">
              <h2><strong>会展大数据</strong></h2>
            </div>
            <div class="content">
              <p>益霖会展大数据平台介绍（原理） 通过大数据对会展相关数据进行采集、清洗、上传、入库，从而建立与会展相关数据库，其中包含展会基础数据库、场馆数据库、参展商数据库、观众数据库等。根据客户的定制化需求，抓取并筛选城市的展会及相关数据信息，同时实现当地全部展会信息的多维度统计分析，另可任意勾选其他省份/城市进行会展对比分析。分析结果通过交互图表以月报、年报的形式进行可视化呈现。</p>
            </div>
          </div>
        </div>
        <div class="row mb-5" v-show="!isShow">
          <div class="col-md-6">
            <div class="title">
              <h2><strong>会展大数据</strong></h2>
            </div>
            <div class="content mb-4">
              <p>益霖会展大数据平台介绍（原理） 通过大数据对会展相关数据进行采集、清洗、上传、入库，从而建立与会展相关数据库，其中包含展会基础数据库、场馆数据库、参展商数据库、观众数据库等。根据客户的定制化需求，抓取并筛选城市的展会及相关数据信息，同时实现当地全部展会信息的多维度统计分析，另可任意勾选其他省份/城市进行会展对比分析。分析结果通过交互图表以月报、年报的形式进行可视化呈现。</p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="img-box">
              <img src="http://wwwsauoscom.oss-cn-shanghai.aliyuncs.com/www/images/03hzdsj/debf/hzdsj01-02.jpg?x-oss-process=style/yilininfo" alt="">
            </div>
          </div>
        </div>
      </div>
      <div id="lk-content-three" class="isShow">
        <div class="tab-title text-center mb-5">
          <h2><strong>我们的服务</strong></h2>
          <p>好品牌来自好口碑，好口碑来自好售后，一次合作，终生合作</p>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="tab-list">
              <ul class="tab-header text-center">
                <li @click="num=0" :class="num==0?'actives':''">展会客流分析</li>
                <li @click="num=1" :class="num==1?'actives':''">客流规模分析</li>
                <li @click="num=2" :class="num==2?'actives':''">客流趋势分析</li>
                <li @click="num=3" :class="num==3?'actives':''">大数据营销</li>
                <li @click="num=4" :class="num==4?'actives':''">会展人群画像</li>
                <li @click="num=5" :class="num==5?'actives':''">广告精准推达</li>
              </ul>
              <div class="main">
                <div class="item" v-for="(item,index) in tabData" :key="index" v-show="num==index">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="item-left">
                        <div class="title mb-4 mt-4">
                          <h4>{{item.title}}</h4>
                        </div>
                        <div class="content">
                          <p>{{item.content}}</p>
                        </div>
                        <div class="detailed">
                          <p class="mt-4">详细  <i class="bi bi-arrow-right"></i></p>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="item-right">
                        <div class="img-box">
                          <img :src="item.url" alt="">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="lk-content-four" class="isShow-two mb-5">
        <div class="tab-title text-center mb-5">
          <h2><strong>我们的服务</strong></h2>
          <p>好品牌来自好口碑，好口碑来自好售后，一次合作，终生合作</p>
        </div>
        <div class="tab">
          <el-collapse  accordion class="item_box">
            <div class="row" id="lk_samll">
              <div class="col-sm-12">
                <el-collapse-item :title="item.title" :name="index" v-for="(item,index) in tabData" :key="index">
                  <div class="item">
                    <div class="content-box">
                      <div class="title">
                        <h5><strong>{{item.title}}</strong></h5>
                      </div>
                      <div class="content">
                        <p>{{item.content}}</p>
                      </div>
                      <div class="detailed">
                        <p class="mt-4">详细  <i class="bi bi-arrow-right"></i></p>
                      </div>
                    </div>
                    <div class="img-box">
                      <img :src="item.url" alt="">
                    </div>
                  </div>
                </el-collapse-item>
              </div>
            </div>
          </el-collapse> 
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:"Convention",
  data() {
    return {
      num:0,
      isShow:true,
      screenWidth:'',
      activeName:1,
      tabData:[
        {header:'展会客流分析',url:'http://wwwsauoscom.oss-cn-shanghai.aliyuncs.com/www/images/03hzdsj/dsbf/hzdsj01.jpg?x-oss-process=style/yilininfo',title:'大数据展会现场热力图',content:'帮助展会主办方统计分析展会最受欢迎品牌/参展商排名、最热最冷展区/展台分析、展区布局优化、展位报价优化、市场热度分析、展会现场人流监控等'},
        {header:'客流规模分析',url:'http://wwwsauoscom.oss-cn-shanghai.aliyuncs.com/www/images/03hzdsj/dsbf/hzdsj02.jpg?x-oss-process=style/yilininfo',title:'大数据客流规模分析',content:'通过大数据分析帮助展会主办方精确了解展会现场客流规模、参展观众意向性及粘性分析,通过人群画像为企业提供精准地了解用户消费行为、兴趣偏好等特征的数据依据,细分核心'},
        {header:'客流趋势分析',url:'http://wwwsauoscom.oss-cn-shanghai.aliyuncs.com/www/images/03hzdsj/dsbf/hzdsj03.jpg?x-oss-process=style/yilininfo',title:'大数据客流趋势分析',content:'基于人脸识别实现深度客流统计,人群洞察,多维交叉分析.分析不同顾客类型,性别,年龄段的客流情况,到店时间,转化率,掌控数据的力量.'},
        {header:'大数据营销',url:'http://wwwsauoscom.oss-cn-shanghai.aliyuncs.com/www/images/03hzdsj/dsbf/hzdsj04.png?x-oss-process=style/yilininfo',title:'大数据营销',content:'大数据营销衍生于互联网行业，又作用于互联网行业。依托多平台的大数据采集，以及大数据技术的分析与预测能力，能够使广告更加精准有效，给品牌企业带来更高的投资回报率。'},
        {header:'会展人群画像',url:'http://wwwsauoscom.oss-cn-shanghai.aliyuncs.com/www/images/03hzdsj/dsbf/hzdsj05.jpg?x-oss-process=style/yilininfo',title:'大数据会展人群画像',content:'个推大数据人群画像 涵盖用户属性,兴趣偏好,线下场景等多个维度,标签丰富,定制灵活,全面立体地洞察目标用户,为精细化运营提供全方位的画像支持.个推大数据人群画像'},
        {header:'广告精准推达',url:'http://wwwsauoscom.oss-cn-shanghai.aliyuncs.com/www/images/03hzdsj/dsbf/hzdsj06.jpg?x-oss-process=style/yilininfo',title:'大数据广告精准推达',content:'精准推送,手机推送,神策数据,用户数据分析工具,自定义多维度分析,活动推送,深度洞察用户数据,可实现多维度,精细化的统计分析,秒级处理,实时更新支持私有化部署的数据分析'}
      ]
    }
  },
  watch:{
    screenWidth(val){
      if(val+17 <= 767){
        this.isShow=false
      }else{
        this.isShow=true
      }
    }
  },
  mounted() {
    this.screenWidth = document.documentElement.clientWidth
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.documentElement.clientWidth;
      })();
    };
  },
  methods: {
    
  },
}
</script>

<style lang="less" scoped>
  .pageWarpp{
    margin-top: 100px;
  }
  .actives{
    color: black !important;
    border-bottom: none !important;
  }
  .hover{
    transition: all .3s;
  }
  .hover:hover{
    transform: scale(1.05);
  }
  .page_title{
    p{
      font-size: 17px;
      color: #6E6E6E;
    }
  }
  #lk-content-one{
    padding-top: 50px;
    .modular_box{
      .img_box{
        overflow: hidden;
        img{
          width: 100%;
        }
      }
      .title{
        padding: 2rem 0;
        color: black;
      }
    }
  }
  #lk-content-two{
    .img-box{
      padding-right: 2rem;
      img{
        width: 100%;
      }
    }
    .content{
      margin-top: 3rem;
      text-align: justify;
      color: #6E6E6E;
    }
  }
  #lk-content-three{
    .tab-list{
      ul{
        list-style: none;
        display: flex;
        width: 100%;
        border: 1px solid #E5E5E5;
        border-bottom: none;
        padding: 0;
        margin-bottom: 0;
        li{
          flex: 1;
          cursor: pointer;
          line-height: 3rem;
          border-bottom: 1px solid #E5E5E5;
          border-left: 1px solid #E5E5E5;
          color: #6E6E6E;
        }
        li:nth-of-type(1){
          border-left: none;
        }
      }
    }
    .main{
      border: 1px solid #E5E5E5;
      border-top: none;
      margin-bottom: 5rem;
      min-height: 20rem;
      .item{
        padding: 2.5rem 2rem;
        .item-left{
          p{
            color: #6E6E6E;
            text-align: justify;
          }
        }
        .item-right{
          width: 100%;
          display: flex;
          justify-content: flex-end;
          .img-box{
            width: 18rem;
            img{
              width: 100%;
            }
          }
        }
      }
    }
  }
  #lk-content-three,#lk-content-four{
    margin-top: 8rem;
    .tab-title{
      p{
        font-size: 17px;
        color: #6E6E6E;
      }
    }
    .detailed{
      cursor: pointer;
      font-size: 18px;
      position: relative;
      i{
        transition: all .5s;
      }
      p{
        transition: all .5s;
      }
      p:hover{
        color: royalblue;
        i{
          position: absolute;
          transform: translateX(30%);
        }
      }
    }
  }
  #lk-content-four{
    .item{
      padding: .5rem;
      border-top: 1px solid #EBEEF5;
    }
    .content-box{
      .title{
        padding: .8rem 0;
      }
    }
    .img-box{
      width: 100%;
      box-shadow: 0 0 10px grey;
      text-align: center;
      img{
        width: 18rem;
        height: 100%;
      }
    }
    .content{
      p{
        color: #6E6E6E;
        text-align: justify;
      }
    }
  }
  .isShow{
    display: block;
  }
  .isShow-two{
    display: none;
  }
  @media screen and (max-width:1199px) {
    #lk-content-two{
      .img-box{
        padding-right: 0 !important;
      }
    }
  }
  @media screen and(max-width:767px) {
    .isShow{
      display: none !important;
    }
    .isShow-two{
      display: block !important;
    }
  }
</style>