import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import { Message,Dialog,Collapse,CollapseItem,Backtop } from 'element-ui'
import jquery from 'jquery'
import "bootstrap/dist/css/bootstrap.min.css"
import 'bootstrap-icons/font/bootstrap-icons.css'
import "bootstrap/dist/js/bootstrap.bundle"
import 'wowjs/css/libs/animate.css'
import videojs from 'video.js'
import 'videojs-contrib-hls'
import 'video.js/dist/video-js.css' 
import VueClipboard from 'vue-clipboard2'
import VueAMap from 'vue-amap'
import axios from 'axios'
// axios.defaults.baseURL = 'http://172.145.0.157:8888/web'
axios.defaults.baseURL = 'https://api.java.sauos.com/web'
Vue.prototype.$http=axios
axios
Vue.use(VueAMap)
VueAMap.initAMapApiLoader({
  key: 'yilinguanwang',
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'],
  // 默认高德 sdk 版本为 1.4.4
  v: '1.4.4',
  uiVersion: '1.0.11'
});

// import '../src/assets/js/rem.js'
Vue.use(VueClipboard)
Vue.use(Dialog)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Backtop)
Vue.prototype.$message = Message
new Vue({
  jquery,
  router,
  store,
  beforeCreate(){
    Vue.prototype.$video = videojs
    Vue.prototype.$bus = this
  },
  render: h => h(App)
}).$mount('#app')
